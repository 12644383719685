import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAppSelector } from '../../redux/hooks';
import AddPaymentMethodModal, { initialPaymentState } from '../payment-method-modal/AddPaymentMethodModal';
import SignOutModal from '../sign-out-modal/SignOutModal';
import NotificationBanner from '../notificationBanner/NotificationBanner';
import { CartSVG } from '../svgs';
import { centsToDollars } from '../../services/account.service';
import { doesAccountHasPendingPaymentTransaction, doesUserHaveSettingAccess } from '../../services/payment.service';
import { PermissionService } from '../../services/permission.service';
import { showToast } from '../../services/toast.service';
import { AccountRoles, AppRoles, PaymentMethods } from '../../types/enums';
import { PaymentMethod } from '../../types/interfaces/paymentMethod.interface';
import { getPaymentDetailsByAccountId, getSubscriptionStatus } from '../../api/paymentGatewayApi';
import { ACCOUNT_PATHS, APP_PATHS } from '../../constants/routePaths';
import { BUTTON_CONSTANTS, NOTIFICATION_CONSTANTS, PAGE_HEADER_CONSTANTS, RSG_CONSTANTS } from '../../constants/common';
import { toastMessages } from '../../constants/errorMessages';

const TitleBar = ({ showSignOut = false }: { showSignOut: boolean }): JSX.Element => {
  const accountId = useAppSelector(state => state.activeAccount.accountId);
  const accountRoles = useAppSelector(state => state.auth.accountContext.roles) as AccountRoles[];
  const activeAccount = useAppSelector(state => state.activeAccount);
  const appRoles = useAppSelector(state => state.auth.appContext.roles) as AppRoles[];
  const cartItems = useAppSelector(state => state.transactionCart.transactionCartItems);
  const enabled = useAppSelector(state => state.user.enabled);
  const paymentStatus = useAppSelector(state => state.payment.addedPaymentMethod);
  const user = useAppSelector(state => state.user);
  const verified = useAppSelector(state => state.user.verified);

  const [addPaymentMethodModalVisible, setAddPaymentMethodModalVisible] = useState(false);
  const [paymentInfo, setPaymentInfo] = useState<PaymentMethod>(initialPaymentState);
  const [pendingACHPayment, setPendingACHPayment] = useState<boolean>();
  const [showPayNowButton, setShowPayNowButton] = useState<boolean>();
  const [userHaveSettingAccess, setUserHaveSettingAccess] = useState<boolean>();

  const permissionService = new PermissionService(accountRoles, appRoles, enabled, verified);

  const checkPaymentValidity = () => {
    return activeAccount?.billingAmount?.outstandingBalanceCents > 0 || activeAccount?.billingAmount?.penaltyCents > 0;
  };

  useEffect(() => {
    if (accountId) {
      doesUserHaveSettingAccess(accountId, user?.userId)
        .then(response => {
          setUserHaveSettingAccess(response);
          checkPaymentValidity() && setShowPayNowButton(response);
        })
        .catch(error => {
          showToast.error(toastMessages.SOMETHING_WENT_WRONG);
        });
    }
  }, [activeAccount]);

  useEffect(() => {
    if (accountId && permissionService.userHasSettingsPermission()) {
      doesAccountHasPendingPaymentTransaction()
        .then(response => {
          setPendingACHPayment(response);
        })
        .catch(error => {
          showToast.error(toastMessages.SOMETHING_WENT_WRONG);
        });
    }
  }, [activeAccount]);

  const getPaymentDetails = async () => {
    if (accountId && paymentStatus && activeAccount?.billingEnabled) {
      try {
        const { data: isUserSubscribed } = await getSubscriptionStatus(accountId);
        if (isUserSubscribed) {
          const { data: paymentDetailsResponse } = await getPaymentDetailsByAccountId(accountId);
          let accountData =
            paymentDetailsResponse?.paymentMethod === PaymentMethods.CARD
              ? {
                  cardNumber: {
                    value: paymentDetailsResponse?.maskedCardNumber,
                    valid: paymentDetailsResponse?.maskedCardNumber?.length > 0 ? true : false,
                  },
                  expiry: {
                    value: paymentDetailsResponse?.expiry,
                    valid: paymentDetailsResponse?.expiry?.length > 0 ? true : false,
                  },
                  accountNumber: { value: '', valid: false },
                  accountType: { value: '', name: '' },
                }
              : {
                  cardNumber: { value: '', valid: false },
                  expiry: { value: '', valid: false },
                  accountNumber: {
                    value: paymentDetailsResponse?.maskedCardNumber,
                    valid: paymentDetailsResponse?.maskedCardNumber?.length > 0 ? true : false,
                  },
                  accountType: { value: paymentDetailsResponse?.accountType, name: paymentDetailsResponse?.accountType },
                };

          setPaymentInfo({
            paymentType: {
              value: paymentDetailsResponse?.paymentMethod,
              valid: paymentDetailsResponse?.paymentMethod ? true : false,
            },
            name: { value: paymentDetailsResponse?.name, valid: paymentDetailsResponse?.name?.length > 0 ? true : false },
            address: {
              value: paymentDetailsResponse?.address,
              valid: paymentDetailsResponse?.address?.length > 0 ? true : false,
            },
            city: { value: paymentDetailsResponse?.city, valid: paymentDetailsResponse?.city?.length > 0 ? true : false },
            region: { value: paymentDetailsResponse?.region, valid: paymentDetailsResponse?.region?.length > 0 ? true : false },
            postalCode: {
              value: paymentDetailsResponse?.postal,
              valid: paymentDetailsResponse?.postal?.length > 0 ? true : false,
            },
            country: {
              value: paymentDetailsResponse?.country,
              valid: paymentDetailsResponse?.country?.length > 0 ? true : false,
            },
            routingNumber: { value: '', valid: false },
            cvv: { value: '', valid: false },
            ...accountData,
          });
        }
      } catch {
        showToast.error(toastMessages.SOMETHING_WENT_WRONG);
      }
    }
  };

  useEffect(() => {
    permissionService.userHasSettingsPermission() && getPaymentDetails();
  }, [addPaymentMethodModalVisible, accountId]);

  const accountSettingsLink = (
    <Link to={ACCOUNT_PATHS.ACCOUNT_SETTINGS} className="payment-details-link" state={{ activeTab: 2 }}>
      {PAGE_HEADER_CONSTANTS.SETTINGS}
    </Link>
  );

  const getNotificationMessage = () => {
    if (userHaveSettingAccess != undefined && userHaveSettingAccess) {
      return <p>To access all the features of this account, please go to {accountSettingsLink} and add your payment details.</p>;
    } else {
      return <p>{NOTIFICATION_CONSTANTS.ADD_PAYMENT_DETAILS}</p>;
    }
  };

  return (
    <div className="title-bar">
      <div className="logo">{RSG_CONSTANTS.RSG_TITLE}</div>
      {showSignOut ? (
        <div className="sign-out-link">
          <SignOutModal />
        </div>
      ) : (
        <div className="notification-cart-container">
          {user?.accounts?.length > 0 && paymentStatus != undefined && !paymentStatus && (
            <>
              <NotificationBanner
                children={<div className="payment-details-not-found-notification">{getNotificationMessage()}</div>}
              />
            </>
          )}
          {user?.accounts?.length > 0 &&
            paymentStatus != undefined &&
            paymentStatus &&
            (activeAccount?.billingAmount?.outstandingBalanceCents > 0 ? (
              <>
                {!pendingACHPayment ? (
                  <NotificationBanner
                    children={
                      <div className="pay-now-notification">
                        <p className={`${!showPayNowButton ? 'full-width-pay-now-notification' : ''}`}>
                          {activeAccount?.billingAmount?.penaltyCents > 0 ? (
                            <>
                              This account has a balance due of{' '}
                              <b>${centsToDollars(activeAccount?.billingAmount?.totalFineAmountCents)}</b>. (Includes{' '}
                              <b>${centsToDollars(activeAccount?.billingAmount?.outstandingBalanceCents)}</b> outstanding balance,{' '}
                              <b>${centsToDollars(activeAccount?.billingAmount?.lateFeeCents)}</b> late fee,{' '}
                              <b> ${centsToDollars(activeAccount?.billingAmount?.financeChargeCents)}</b> finance charge of{' '}
                              {activeAccount?.billingAmount?.financeChargeRate}%)
                            </>
                          ) : (
                            <>
                              This account has a balance due of{' '}
                              <b>${centsToDollars(activeAccount?.billingAmount?.totalFineAmountCents)}</b>. Please pay the balance
                              to avoid additional charges.
                            </>
                          )}
                        </p>
                        {showPayNowButton != undefined && showPayNowButton && (
                          <button
                            className="button green small pay-now-button"
                            type="button"
                            onClick={() => setAddPaymentMethodModalVisible(true)}>
                            {BUTTON_CONSTANTS.PAY_NOW}
                          </button>
                        )}
                      </div>
                    }
                  />
                ) : (
                  <>
                    <NotificationBanner
                      children={
                        <div className="payment-details-not-found-notification">
                          <p>{NOTIFICATION_CONSTANTS.PENDING_ACH_PAYMENT}</p>
                        </div>
                      }
                    />
                  </>
                )}
              </>
            ) : (
              activeAccount?.billingAmount?.outstandingBalanceCents <= 0 &&
              activeAccount?.locked && (
                <>
                  <NotificationBanner
                    children={
                      <div className="payment-details-not-found-notification">
                        <p>{NOTIFICATION_CONSTANTS.ACCOUNT_LOCKED}</p>
                      </div>
                    }
                  />
                </>
              )
            ))}

          {(permissionService.userHasRSGAdminPermission() || permissionService.userHasInventoryPermission()) && (
            <Link className="button icon" to={APP_PATHS.TRANSACTION_CART}>
              <CartSVG />
              {cartItems.length > 0 && <span className="cart-item" />}
            </Link>
          )}

          {accountId && addPaymentMethodModalVisible && (
            <AddPaymentMethodModal
              title={BUTTON_CONSTANTS.PAY_NOW}
              accountId={accountId}
              paymentDetails={paymentInfo}
              isOpen={addPaymentMethodModalVisible}
              setIsOpen={setAddPaymentMethodModalVisible}
              onclick={() => setAddPaymentMethodModalVisible(false)}
              buttonAction={'PayNow'}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default TitleBar;
