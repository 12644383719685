import { Validators } from '../types/enums/validators.enum';

export const FormErrors = [
  {
    validator: Validators.REQUIRED,
    condition: /./,
    message: 'This field is required.',
  },
  {
    validator: Validators.PASSWORD_LENGTH,
    condition: /.{12,}/,
    message: 'Password must be at least 12 Characters long',
  },
  {
    validator: Validators.PHONE_LENGTH,
    condition: /^.{3,15}$/,
    message: 'Must be a valid phone number length',
  },
  {
    validator: Validators.NEEDS_UPPER,
    condition: /[A-Z]/,
    message: 'Please include at least 1 Upper Case',
  },
  {
    validator: Validators.NEEDS_LOWER,
    condition: /[a-z]/,
    message: 'Please include at least 1 Lower Case',
  },
  {
    validator: Validators.NEEDS_NUMBER,
    condition: /\d/,
    message: 'Please include at least 1 Number',
  },
  {
    validator: Validators.NEEDS_SPECIAL,
    condition: /[!@#$&*]/,
    message: 'Please include at least 1 Special Character (!@#$&*)',
  },
  {
    validator: Validators.NEEDS_UPPER_LOWER,
    condition: /^[a-zA-Z ]*$/,
    message: 'Please enter only alphabets',
  },
  {
    validator: Validators.EMAIL,
    condition: /^\S+@\S+\.\S+[^.]$/,
    message: 'Must be a valid Email address',
  },
  {
    validator: Validators.MUST_MATCH,
    message: 'This field must match',
  },
  {
    validator: Validators.MIN_VALUE,
    message: 'Value is too small',
  },
  {
    validator: Validators.MAX_VALUE,
    message: 'Value is too large',
  },
  {
    validator: Validators.CARD_NUMBER,
    condition: /^[0-9]{8,25}$/,
    message: 'Must be a valid card number length',
  },
  {
    validator: Validators.EXPIRY_DATE_LENGTH,
    condition: /^(0[1-9]|1[0-2])\/?([1-9]{1}[0-9]{1})$/,
    message: 'Must be a valid expiry date',
  },
  {
    validator: Validators.ACCOUNT_NUMBER,
    condition: /^[0-9]{5,25}$/,
    message: 'Must be a valid account number length',
  },
  {
    validator: Validators.ROUTING_NUMBER,
    condition: /^[0-9]{4,15}$/,
    message: 'Must be a valid routing number length',
  },
  {
    validator: Validators.CVV_NUMBER,
    condition: /^[0-9]{3,5}$/,
    message: 'Must be a valid cvv number length',
  },
  {
    validator: Validators.ZIP_CODE,
    condition: /(^\d{5}$)|(^\d{5}-\d{4}$)/,
    message: 'Must be a valid zip code',
  },
];

export const customFormErrors = {
  EMAIL_IN_USE: 'This email is already in use, please enter a different email or sign in',
};

export const toastMessages = {
  ACCOUNT_CREATED: 'You have successfully created your account!',
  ADD_PAYMENT_METHOD_FAIL: 'You have entered an invalid details',
  ADD_PAYMENT_METHOD_SUCCESS: 'Successfully added payment details',
  ADD_PAYMENT_UPDATED: 'Successfully updated payment method',
  ADD_REQUEST_TO_CART_SUCCESS: 'The request has been successfully added to the transaction cart.',
  ADD_TEAM_MEMBER_FAIL: 'Unable to add member to your team',
  ADD_TEAM_MEMBER_SUCCESS: 'You have successfully added a member to your team',
  ANIMAL_CREATED: 'New Animal has been successfully created.',
  ANIMAL_EDITED: 'Update Successful!',
  ANIMAL_IMAGE_ADDED: 'Animal image has been added!',
  ANIMAL_IMAGE_REMOVED: 'Animal image has been removed!',
  BREED_CREATED_FAIL: 'Breed was unable to be created.',
  BREED_CREATED_SUCCESS: 'Breed has been created successfully.',
  BREED_CREATED: 'New Breed has been successfully created.',
  BREED_UPDATE_FAIL: 'Breed was unable to be updated.',
  BREED_UPDATE_SUCCESS: 'Breed has been updated successfully.',
  BUSINESS_IN_USE: 'That Business Name is already in use.',
  CANISTER_CREATED_FAIL: 'Canister was unable to be created.',
  CANISTER_CREATED_SUCCESS: 'Canister has been created successfully.',
  CANISTER_UPDATE_FAIL: 'Canister was unable to be updated',
  CANISTER_UPDATE_SUCCESS: 'Canister was updated successfully.',
  CANT_ADJUST_WITHOUT_TRANSACTION_OR_ACCOUNT_ID: 'Cannot submit without transaction or accountId',
  CANT_SELL_MORE_THAN_AVAIL_QTY: "You can't sell/transfer more than the Available Qty",
  CANT_USE_MORE_THAN_AVAIL_QTY: "You can't use more than the Available Qty",
  CANT_WITHDRAW_MORE_THAN_AVAIL_QTY: "You can't withdraw more than the Available Qty",
  CHECK_IN_SUCCESS: 'Check In completed successfully!',
  CHECK_OUT_SUCCESS: 'Check Out completed successfully!',
  CHOSE_LESS_THAN_ZERO: 'You can’t choose negative quantities',
  CHOSE_MORE_THAN_OWNED: 'You can’t choose more than the owned quantity.',
  CHOSE_MORE_THAN_TOTAL: 'You can’t choose more than the quantity in the canister.',
  COLLECT_SUCCESSFUL: 'Collect Successful',
  CUSTOM_DATA_POINT_ACTIVATED: 'Custom data point has been activated successfully.',
  CUSTOM_DATA_POINT_CREATED: 'Custom data point has been created successfully.',
  CUSTOM_DATA_POINT_DEACTIVATED: 'Custom data point has been deactivated successfully.',
  CUSTOM_DATA_POINT_UPDATED: 'Custom data point has been updated successfully.',
  DEACTIVATE_ACCOUNT_SUCCESS: 'You have successfully deactivated account.',
  DEACTIVATED_ACCOUNT: 'account is deactivated.',
  DEPOSIT_INITIATED: 'Deposit has been initiated',
  DEPOSIT_SUCCESSFUL: 'Deposit Successful',
  DISCARD_SUCCESSFUL: 'Discard successfully completed',
  DISCARD_SUCCESSFULLY_REQUESTED: 'Discard successfully requested',
  DUPLICATE_ACCOUNT: 'This account is already present.',
  DUPLICATE_LOCATION: 'This location is already present.',
  EMAIL_NOT_FOUND: 'No user was found with this email.',
  EXPIRED_INCORRECT_LINK: 'That link has expired.',
  FAILED_ADD_USER_TO_ACCOUNT: 'Failed to add user to account',
  FAILED_DEPOSIT_INITIATED: 'Failed to submit deposit request.',
  FAILED_TO_ADD_ANIMAL_IMAGE: 'Failed to add animal images',
  FAILED_TO_REMOVE_ANIMAL_IMAGE: 'Failed to remove animal images',
  FAILED_TO_RESEND_INVITE: 'Failed to Resend Invite',
  FAILED_TO_RETRIEVE_LINKED_STORAGE_MOVEMENTS: 'Failed to retrieve linked storage movements.',
  FAILED_TO_RETRIEVE_LINKED_TRANSACTIONS: 'Failed to retrieve linked transactions',
  FAILED_TO_RETRIEVE_SPECIMEN_ACCOUNTS: 'Failed to retrieve specimen accounts.',
  FAILED_TO_RETRIEVE_SPECIMEN: 'Failed to retrieve specimen info.',
  FAILED_TO_RETRIEVE_USERS: 'Failed to retrieve users',
  FAILED_TO_SET_NEW_PASSWORD: 'Failed to set password',
  FAILED_TO_SUBMIT_SALE: 'Failed to submit sale.',
  FAILED_TO_SUBMIT_USE: 'Failed to submit use request.',
  FAILED_TO_SUBMIT_WITHDRAW: 'Failed to submit withdraw request.',
  FAILED_TO_UPDATE_INVENTORY: 'Failed to update inventory',
  FAILED_TO_UPDATE_TEAM_MEMBER: 'Failed to update team member.',
  GLOBAL_THRESHOLD_SUCCESS: 'Global threshold value has been updated successfully.',
  IMAGE_LARGER_THAN_3MB: 'The selected image is too large. Please select an image smaller than 3 MB.',
  INCORRECT_CODE: 'That code is incorrect or has expired!',
  INCORRECT_EMAIL_PASSWORD: 'Email or Password is incorrect!',
  INVALID_DISCARD_QUANTITY: 'Cannot discard more than the available quantity.',
  INVALID_FILE_TYPE: 'Invalid file type. Please upload a jpg or png file.',
  INVALID_SELL_CODE: 'Invalid Sell Code',
  INVALID_ZERO_QUANTITY: 'Cannot submit with a quantity of zero units.',
  INVITE_RESENT: 'Invite Resent!',
  LOCK_STATUS_SUCCESS: 'The account lock status has been successfully changed.',
  MARK_AS_PAID_SUCCESS: 'Account has been successfully marked as paid.',
  MARKED_AS_PROCESSED: 'Transaction has been marked as processed.',
  MISSING_REQUIRED_FIELDS: 'Missing required fields, please fill out all required fields',
  NAME_TAKEN: 'That name is already taken.',
  NETWORK: 'A network error has occurred. Please try again later.',
  NO_CHANGES_SAVED: 'No changes were saved',
  NO_ITEM_SELECTED: 'No item(s) selected for discard.',
  NO_OWNERS_FOUND: 'Animal must have at least one owner',
  NONEXISTENT_EMAIL: 'That Email does not exist!',
  PASSWORD_CANNOT_BE_SAME: 'Your new password cannot be the same as your old password. Please choose a different one.',
  PASSWORD_RESET_SUCCESS: 'You have successfully reset your password'!,
  PAYMENT_FAIL: 'Payment Failed',
  PAYMENT_SUCCESS: 'Successfully made the payment',
  PENDING_USE_ALREADY_EXISTS: 'One or more of the animals you are trying to use already has a pending use request.',
  QUANTITIES_ADJUSTED_SUCCESSFULLY: 'Quantities have been updated successfully.',
  QUANTITIES_GREATER_THAN_CANISTER_QTY: ' Total must not be greater than canister quantity',
  QUANTITIES_LESS_THAN_CANISTER_QTY: 'Total must not be less than canister quantity',
  REMOVE_TEAM_MEMBER_FAIL: 'Failed to remove member from your team',
  REMOVE_TEAM_MEMBER_SUCCESS: 'You have successfully removed a member from your team',
  REMOVE_TRANSACTION_CART_ITEM_FAIL: 'Failed to remove item from the transaction cart.',
  REMOVE_TRANSACTION_CART_ITEM: 'Cart item has been successfully removed.',
  RESET_PASSWORD_EMAIL_SENT: 'The password reset email was sent successfully',
  SALE_HAS_BEEN_SUBMITTED: 'Your sale has been submitted.',
  SELECT_STORAGE_PLAN: 'Select a storage plan to continue.',
  SELLER_AND_BUYER_MUST_BE_DIFFERENT: 'Seller and buyer must be different',
  SESSION_EXPIRED: 'Your session has expired. Please sign in again.',
  SETTINGS_UPDATED: 'Settings updated.',
  SITE_CREATED_FAIL: 'Site was unable to be created.',
  SITE_CREATED_SUCCESS: 'Site has been created successfully.',
  SITE_UPDATE_FAIL: 'Site was unable to be updated',
  SITE_UPDATE_SUCCESS: 'Site was updated successfully.',
  SOME_IMAGES_LARGER_THAN_3MB: 'Some of the selected images are too large. Please select images smaller than 3 MB.',
  SOMETHING_WENT_WRONG: 'Something went wrong.',
  SPECIES_CREATED_FAIL: 'Species was unable to be created.',
  SPECIES_CREATED_SUCCESS: 'Species has been created successfully.',
  SPECIES_CREATED: 'New Species has been successfully created.',
  SPECIES_UPDATE_FAIL: 'Species was unable to be updated.',
  SPECIES_UPDATE_SUCCESS: 'Species has been updated successfully.',
  SPECIMEN_UPDATE_FAIL: 'Failed to update inventory item.',
  SPECIMEN_UPDATE_SUCCESS: 'Successfully updated inventory item.',
  STORAGE_PLAN_ADD_ERROR: 'There was an error adding your storage plan.',
  STORAGE_PLAN_ADDED: 'You have successfully selected a storage plan!',
  SUCCESS: 'Success!',
  SUCCESSFULLY_SET_NEW_PASSWORD: 'Successfully set new password!',
  SUCCESSFULLY_UPDATED_TRANSACTION: 'You successfully updated the transaction',
  TANK_CREATED_FAIL: 'Tank was unable to be created.',
  TANK_CREATED_SUCCESS: 'Tank has been created successfully.',
  TANK_UPDATE_FAIL: 'Tank was unable to be updated',
  TANK_UPDATE_SUCCESS: 'Tank was updated successfully.',
  TEAM_MEMBER_EXISTS_ERROR: 'Team member already exists',
  TOTAL_QTY_MISMATCH: 'Quantities must match to submit.',
  TRANSACTION_DISCARD_MISMATCH: 'Transaction discard total quantity must equal requested change quantity.',
  TRANSACTION_SUCCESS: 'Your transaction was successful.',
  UPLOAD_MAX_5_IMAGES: 'You can only upload a maximum of 5 images',
  USE_HAS_BEEN_SUBMITTED: 'Your use request has been submitted.',
  USER_IMAGE_UPDATE_SUCCESS: 'Your profile image has been updated!',
  USER_MISSING_ACCOUNT: 'User does not have any accounts associated with it.',
  USER_UPDATE_SUCCESS: 'Your profile has been updated!',
  VARIANCE_WARNING: 'Variance must be 0. Review Inventory Integrity for details.',
  VERIFIED_ACCOUNT_EMAIL: 'You have successfully verified your account!',
  WITHDRAW_HAS_BEEN_SUBMITTED: 'Your withdraw request has been submitted.',
  WITHDRAW_SUBMIT_FAIL: 'Failed to submit withdraw',
  WITHDRAW_SUBMITTED: 'Withdraw has been submitted!',
  WITHDRAW_SUCCESS: 'Withdraw successfully completed',
};

export const PromiseError = {
  BAD_REQUEST: 'ERR_BAD_REQUEST',
  NETWORK: 'ERR_NETWORK',
};

export const apiErrorTitles = {
  BAD_FILE_TYPE: 'File content type are not proper: ',
  PENDING_USE_ALREADY_EXISTS:
    'Unable to process request due to a data conflict: A Pending Use InventoryTransaction already exists for one of the Account/Specimen combinations',
  USER_ALREADY_EXISTS: 'Conflict: User: An error occurred while saving the entity changes. See the inner exception for details.',
};

export const clipboardError = {
  NO_SUPPORT: 'Missing support for clipboard API',
};

export const accountSearchErrors = {
  ACCOUNT_FOUND: '✓ Account Found!',
  ACCOUNT_NOT_FOUND:
    'No User has been found, please provide the information below and an invitation will be sent to this email address.',
};
