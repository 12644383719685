import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { BackButton, Modal, SelectStorageItems } from '../../components';
import PagedTable from '../../components/sorted-table/PagedTable';
import ActionMenu from '../../components/action-buttons/ActionButtons';
import SpecimenViewEdit from '../animal-management/SpecimenViewEdit';
import ReconcileInventoryModal from './ReconcileInventoryModal';
import { InventoryManagementMoveAutoFill } from './InventoryManagementMove';
import { SpecimenLocation, selectOption } from '../../types/interfaces';
import { StorageTankOptions } from '../../types/enums';
import { getStorageCanisterCount } from '../../api/storageItemsApi';
import { getSpecimenLocations } from '../../api/specimensApi';
import { ROUTE_PATHS } from '../../constants/routePaths';
import { toastMessages } from '../../constants/errorMessages';
import { BUTTON_CONSTANTS, LABEL_CONSTANTS, PAGE_HEADER_CONSTANTS, TABLE_HEADER_CONSTANTS } from '../../constants/common';
import { showToast } from '../../services/toast.service';
import './inventoryManagement.scss';

export interface InventoryManagementCanisterContentsAutoFill {
  site: selectOption;
  tank: selectOption;
  canister: selectOption;
}

const InventoryManagementCanisterContents = (): JSX.Element => {
  const navigate = useNavigate();
  const { state: autoFill } = useLocation();

  const [site, setSite] = useState<selectOption>(autoFill?.site ?? { value: '', name: '' });
  const [tank, setTank] = useState<selectOption>(autoFill?.tank ?? { value: '', name: '' });
  const [canister, setCanister] = useState<selectOption>(autoFill?.canister ?? { value: '', name: '' });
  const [canisterCount, setCanisterCount] = useState<number>();
  const [specimenInfoModal, setSpecimenInfoModal] = useState<JSX.Element>();
  const [reconcileModal, setReconcileModal] = useState<JSX.Element>();
  const [messageModal, setMessageModal] = useState<JSX.Element>();
  const [refresh, setRefresh] = useState<number>(0);
  const [canisterContents, setCanisterContents] = useState<any>();

  useEffect(() => {
    const getCanisterCount = async () => {
      try {
        const { data: count } = await getStorageCanisterCount(canister.value);
        setCanisterCount(count);
      } catch {
        setCanisterCount(undefined);
        showToast.error(toastMessages.SOMETHING_WENT_WRONG);
      }
    };
    if (canister.value.length > 0) {
      getCanisterCount();
    } else {
      setCanisterCount(undefined);
    }
  }, [canister]);

  const handleGetLocations = useCallback(async () => {
    if (canister.value.length > 0) {
      const query = { include: 'Specimen.Animal', filter: `StorageCanisterId eq ${canister.value}` };

      try {
        const specimenLocations = (await getSpecimenLocations(query)).data;
        setCanisterContents(specimenLocations);
      } catch (error) {
        showToast.error(toastMessages.SOMETHING_WENT_WRONG);
      }
    }
  }, [canister]);

  useEffect(() => {
    handleGetLocations();
  }, [handleGetLocations]);

  const moveSpecimen = (item: SpecimenLocation) => {
    const inventoryMoveAutoFillState: InventoryManagementMoveAutoFill = {
      site: site,
      tank: tank,
      canister: canister,
      selectedSpecimenId: item.specimenId!,
    };

    navigate(ROUTE_PATHS.APP_INVENTORY_MANAGEMENT_MOVE, { state: inventoryMoveAutoFillState });
  };

  const reconcileSpecimen = (item: SpecimenLocation) => {
    setReconcileModal(
      <ReconcileInventoryModal
        item={item}
        close={(success: boolean, message?: string) => {
          setReconcileModal(undefined);
          if (success) {
            setRefresh(r => r + 1);
            if (message) {
              setMessageModal(
                <div className="sign-out-modal message-modal">
                  <div className="header">
                    <h2>{LABEL_CONSTANTS.VARIANCE_DETECTED}</h2>
                  </div>
                  <div className="body">
                    <p className="modal-p-side-margin"> {message}</p>
                  </div>
                  <div className="flex-right footer">
                    <button className="small green button" onClick={() => setMessageModal(undefined)}>
                      {BUTTON_CONSTANTS.OK}
                    </button>
                  </div>
                </div>,
              );
            }
          }
        }}
      />,
    );
  };

  const lotInfoSpecimen = (item: SpecimenLocation) => {
    setSpecimenInfoModal(<SpecimenViewEdit itemId={item.specimenId} />);
  };

  return (
    <div className="inventory-management-canister-contents">
      <div className="header">
        <h1>{PAGE_HEADER_CONSTANTS.CANISTER_CONTENTS}</h1>
        <SelectStorageItems
          selectedSite={site}
          selectedTank={tank}
          selectedCanister={canister}
          setSelectedSite={setSite}
          setSelectedTank={setTank}
          setSelectedCanister={setCanister}
          storageTankOptions={StorageTankOptions.Both}
        />
      </div>
      <Modal isOpen={reconcileModal !== undefined} onClose={() => setReconcileModal(undefined)} width={510}>
        {reconcileModal}
      </Modal>
      <Modal isOpen={messageModal !== undefined} onClose={() => setMessageModal(undefined)}>
        {messageModal}
      </Modal>
      <Modal isOpen={specimenInfoModal !== undefined} onClose={() => setSpecimenInfoModal(undefined)} width={700}>
        <div className="specimen-info-modal">
          <BackButton onClick={() => setSpecimenInfoModal(undefined)} />
          {specimenInfoModal}
        </div>
      </Modal>
      {canisterCount !== undefined && (
        <label className="canister-count">
          {LABEL_CONSTANTS.CANISTER_INVENTORY_COUNT}: <strong>{canisterCount}</strong>
        </label>
      )}
      <div className="content">
        {canister.value.length > 0 && (
          <PagedTable
            headers={[
              { displayName: TABLE_HEADER_CONSTANTS.ANIMAL_CODE_NAME },
              { displayName: TABLE_HEADER_CONSTANTS.LOT_DATE_NO },
              { displayName: TABLE_HEADER_CONSTANTS.QUANTITY },
              { displayName: TABLE_HEADER_CONSTANTS.ACTIONS },
            ]}
            buildRow={(item: SpecimenLocation) => {
              return [
                `${item.specimen?.animal?.code} - ${item.specimen?.animal?.name}`,
                new Date(item.specimen!.freezeDate).toLocaleDateString() + ' - ' + item.specimenId,
                item.quantity,
                <ActionMenu
                  actionButtons={[
                    { name: 'Move', action: () => moveSpecimen(item) },
                    { name: 'Reconcile', action: () => reconcileSpecimen(item) },
                    { name: 'Lot Info', action: () => lotInfoSpecimen(item) },
                  ]}
                />,
              ];
            }}
            getData={getSpecimenLocations}
            filter={'StorageCanisterId eq ' + canister.value}
            height={500}
            minHeight={220}
            include="Specimen.Animal"
            pageSize={12}
            scrollBufferThickness={50}
            sortBy=""
            refresh={refresh}
            setRefresh={setRefresh}
          />
        )}
      </div>
      <div className="xs-content">
        {canister.value.length > 0 &&
          canisterContents &&
          canisterContents.map((canisterContent: any, index: any) => {
            return (
              <div className="card max-width" key={canisterContent + index}>
                <div className="canister-inventory">
                  <div className="canister-inventory-content">
                    <div className="animal-code">
                      <label>{`${TABLE_HEADER_CONSTANTS.ANIMAL_CODE_NAME} : ${canisterContent?.specimen?.animal?.code} - ${canisterContent?.specimen?.animal?.name}`}</label>
                    </div>
                    <div className="animal-info">
                      <div className="animal-lot-date">
                        <label>{`${TABLE_HEADER_CONSTANTS.LOT_DATE_NO} : ${new Date(
                          canisterContent.specimen!.freezeDate,
                        ).toLocaleDateString()} - ${canisterContent?.specimenId}`}</label>
                      </div>
                      <div className="quantity">
                        <label>{`${TABLE_HEADER_CONSTANTS.QUANTITY} : ${canisterContent?.quantity}`}</label>
                      </div>
                    </div>
                  </div>
                  <div className="action-button">
                    <ActionMenu
                      actionButtons={[
                        { name: 'Move', action: () => moveSpecimen(canisterContent) },
                        { name: 'Reconcile', action: () => reconcileSpecimen(canisterContent) },
                        { name: 'Lot Info', action: () => lotInfoSpecimen(canisterContent) },
                      ]}
                    />
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default InventoryManagementCanisterContents;
